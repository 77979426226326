import React, { useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Arrow from "../images/arrow_back.svg";
import { Typeahead } from "react-bootstrap-typeahead";
import { countryList } from "../constants/countries";
// import charge from './api/charge'

// function to check is object empty or not
function isEmpty(obj) {
  for (let key in obj) {
    // если тело цикла начнет выполняться - значит в объекте есть свойства
    return false;
  }
  return true;
}

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
const CheckoutForm = (props) => {
  const { amount, contactId, events, handleGoBack, paymentReason } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [name, setName] = useState("");
  // const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendReceipt, setSendReceipt] = useState(true);
  const [errors, setErrors] = useState({});


  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = {};
    const cardValidation = elements.getElement(CardElement);
    if (name === "") {
      validationErrors.name = "Name cannot be blank";
    }
    // if (!country.length) {
    //   validationErrors.country = "Country cannot be blank";
    // }
    if (zip === "") {
      validationErrors.zip = "zip cannot be blank";
    }
    if (cardValidation._empty) {
      validationErrors.card = "Card cannot be blank";
    }
    if (isEmpty(validationErrors)) {
      setIsProcessing(true);
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        console.log(result.error.message);
      } else {
        // pass the token to your backend API
        axios
          .post("https://requestloggertishakin.herokuapp.com/api/payment", {
            cardMap: {
              cardToken: result.token.id,
              // cardCountry: country[0].name,
              zip: zip,
              nameOnCard: name,
            },
            amount: amount * 100,
            isSendReceipt: sendReceipt,
            contactId: contactId,
            campaignId: events,
            cardNumber: result.token.card.last4,
            paymentReason: paymentReason
          })
          .then(function (response) {
            if (response.data.message.includes("https")) {
              window.location.replace(response.data.message);
            } else {
              setResponseMessage(response.data.message);
              setTimeout(function(){ window.location.replace("https://www.jewishuae.com/"); }, 3000);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
          });
      }
      setLoading(true);
      setIsProcessing(false);
    }
    console.log("validationErrors->", validationErrors);
    setErrors(validationErrors);
  };

  const iframeStyles = {
    base: {
      fontFamily: "Lato, sans-serif",
      fontSize: "12px",
      "::placeholder": {
        color: "#e0e0e0",
      },
    },
  };

  const cardElementOpts = {
    hidePostalCode: true,
    style: iframeStyles,
  };
  if (loading) return <p>loading...</p>;
  if (responseMessage !== "") {
    return (
      <p className="info-payment-total__sum">{responseMessage}</p>
    );
  }

  return (
    <>
      <div
        className="info-content-header info-content__block2 d-md-none d-lg-none d-xl-none"
        onClick={handleGoBack}
      >
        <img src={Arrow} alt="back" />
        <div className="info-content-header__title">Back to the event</div>
      </div>
      <Form className="form" onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label className="custom-form-label">
            <span>
              Name on card<span className="form-star">*</span>
            </span>
            {errors.name ? (
              <Form.Control.Feedback type="invalid">
                REQUIRED
              </Form.Control.Feedback>
            ) : null}
          </Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            value={name}
            placeholder="Type name"
            onChange={(e) => setName(e.target.value)}
            isInvalid={errors.name}
          />
        </Form.Group>
        {/* <FormField
        name="name"
        label="Name on card"
        type="text"
        placeholder="Type name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      /> */}
        {/*<Form.Group>*/}
        {/*  <Form.Label className="custom-form-label">*/}
        {/*    <span>*/}
        {/*      Country or region<span className="form-star">*</span>*/}
        {/*    </span>*/}
        {/*    {errors.country ? (*/}
        {/*      <Form.Control.Feedback type="invalid">*/}
        {/*        REQUIRED*/}
        {/*      </Form.Control.Feedback>*/}
        {/*    ) : null}*/}
        {/*  </Form.Label>*/}
        {/*  <Typeahead*/}
        {/*    id="basic-typeahead-single"*/}
        {/*    labelKey="name"*/}
        {/*    onChange={(selected) => {*/}
        {/*      setCountry(selected);*/}
        {/*    }}*/}
        {/*    options={countryList}*/}
        {/*    placeholder="Choose a country..."*/}
        {/*    isInvalid={errors.country}*/}
        {/*    //selected={singleSelections}*/}
        {/*  />*/}
        {/*</Form.Group>*/}
        <Form.Group>
          <Form.Label className="custom-form-label">
            <span>
              ZIP<span className="form-star">*</span>
            </span>
            {errors.zip ? (
              <Form.Control.Feedback type="invalid">
                REQUIRED
              </Form.Control.Feedback>
            ) : null}
          </Form.Label>
          <Form.Control
            type="number"
            name="zip"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            isInvalid={errors.zip}
            placeholder="Type ZIP"
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="custom-form-label">
              <span>
                Card number<span className="form-star">*</span>
              </span>
              {errors.card ? (
                <Form.Control.Feedback type="invalid">
                  REQUIRED
                </Form.Control.Feedback>
              ) : null}
            </Form.Label>
            <CardElement
              options={cardElementOpts}
              className={
                errors.card ? "custom-element-warning" : "custom-element"
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check
              type="checkbox"
              id="sendReceipt"
              label="Send receipt"
              checked={sendReceipt}
              onChange={() => setSendReceipt(!sendReceipt)}
            />
          </Form.Group>
        </Form.Row>
        <Button
          className="form-btn"
          type="submit"
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? "Processing..." : `Pay $${amount}`}
        </Button>
      </Form>
    </>
  );
};

const CheckoutFormContainer = (props) => {
  const { amount, contactId, events, handleGoBack, paymentReason } = props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        amount={amount}
        contactId={contactId}
        events={events}
        handleGoBack={handleGoBack}
        paymentReason={paymentReason}
      />
    </Elements>
  );
};

export default CheckoutFormContainer;
