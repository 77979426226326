import React, { useState, useEffect } from "react";
import Logo from "../images/logo.png";
import Arrow from "../images/arrow.svg";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import CheckoutForm from "../components/CheckoutForm";
import axios from "axios";
const currency = "$";

const Main = (props) => {
  const [goToPaymant, setGoToPayment] = useState(false);
  const [contactId, setContactId] = useState();
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [paymentReason, setPaymentReason] = useState('')
  // const [totalAmount, setTotalAmount] = useState("");

  useEffect(() => {
    const data = props.match.params;
    if (data.ContactId) {
      setContactId(data.ContactId);
    }
    if (data.Event1) {
      setEvents(data.Event1);
    }
    console.log("props.match.params", props.match.params);
  }, [props]);

  useEffect(() => {
    if (contactId && events) {
      setLoading(true);
      axios
        .post(`https://requestloggertishakin.herokuapp.com/api/details`, {
          contactId: contactId,
          campaignId: events,
        })
        .then(function (response) {
          setPaymentData(response.data);
          setPaymentReason(response.data.paymentReason)
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }
  }, [contactId, events]);

  // let renderAmount = '';
  // if (paymentData.isDonation) {
  //   setTotalAmount(currency + paymentData.donationAmount)
  // } else {
  //   if (paymentData.paymentStatus === "Partialy Paid") {
  //     setTotalAmount(currency + paymentData.prepaidAmount);
  //   } else {
  //     setTotalAmount(currency + paymentData.amount)
  //   }
  // }

  const renderAmount = () => {
    if (paymentData.isDonation) {
      return paymentData.donationAmount;
    } else {
      if (paymentData.paymentStatus === "Partialy Paid") {
        return paymentData.prepaidAmount;
      } else {
        return paymentData.amount;
      }
    }
  };

  if (!contactId || !events)
    return (
      <div className="container-spinner ">
        <h3>There is some trouble with your payment link</h3>
      </div>
    );
  if (paymentData.message && paymentData.message.includes("Data not found"))
    return (
      <div className="container-spinner ">
        <h3>There is some trouble with your event</h3>
      </div>
    );
  if (loading)
    return (
      <div className="container-spinner">
        <Spinner animation="border" />
      </div>
    );

  const donationContent = () => {
    if (paymentData.paymentReason.includes("donat")) {
      return (
        // <p className="info-content-body-greeting__subtitle">
        //   Thank you! You wanted to {paymentData.paymentReason}, so you can pay
        //   the amount.
        // </p>
        <p className="info-content-body-greeting__subtitle">
          {paymentData.paymentReason}
        </p>
      );
    } else {
      return (
        <>
          <p className="info-content-body-greeting__subtitle">Thank you!</p>
          <div className="info-donation__block">
            <div className="info-donation__title">Items:</div>
            <ul className="info-donation__list">
              {paymentData.paymentReason.split(",").map((item) => {
                let itemStr = item.trim();
                itemStr = itemStr[0].toUpperCase() + itemStr.slice(1);
                return <li key={item}>{itemStr}</li>;
              })}
            </ul>
          </div>
        </>
      );
    }
  };

  const totalAmountFieledRender = () => {
    if (paymentData.isDonation) {
      return (
        <div className="info-payment-total__sum">{`${currency}${paymentData.donationAmount}`}</div>
      );
    } else {
      if (paymentData.paymentStatus === "Partialy Paid") {
        return (
          <div className="info-payment-total__sum">{`${currency}${paymentData.prepaidAmount}`}</div>
        );
      } else {
        return (
          <div className="info-payment-total__sum">{`${currency}${paymentData.amount}`}</div>
        );
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="wrapper">
          <Row className="wrapper-row m-0">
            {!goToPaymant ? (
              <Col md={6} className="p-0">
                <div className="info">
                  <div className="info-content">
                    <div className="info-content-body info-content__block">
                      <div className="info-logo">
                        <img src={Logo} alt="Logo" />
                      </div>
                      <div className="info-content-body-greeting">
                        <h1 className="info-content-body-greeting__title">
                          Hello,{" "}
                          <span className="info-content-body-greeting__name">
                            {paymentData.memberName}!
                          </span>
                        </h1>
                        {paymentData.isDonation ? (
                          donationContent()
                        ) : (
                          <p className="info-content-body-greeting__subtitle">
                            You attended our event and we are asking you for
                            payment.
                          </p>
                        )}
                      </div>
                      <div className="info-content-body__events-list">
                        <div className="info-content-body-details">
                          {!paymentData.isDonation ? (
                            <div className="info-content-body-details-event info-content-body-details__block">
                              <div className="info-content-body-details__title">
                                Event name:
                              </div>
                              <div className="info-content-body-details__value info-content-body-details-event__name">
                                {paymentData.eventName}
                              </div>
                            </div>
                          ) : null}
                          {!paymentData.isDonation ? (
                            <div className="info-content-body-details-date info-content-body-details__block">
                              <div className="info-content-body-details__title">
                                Date
                              </div>
                              <div className="info-content-body-details__value">
                                {paymentData.eventDate}
                              </div>
                            </div>
                          ) : null}

                          {!paymentData.isDonation ? (
                            <div className="info-content-body-details-people">
                              <div className="info-content-body-details__title mb-3">
                                Count of people:
                              </div>
                              <Row className="info-content-body-details-people__list">
                                <Col xs={6} className="mb-4">
                                  <div className="kind">Adult</div>
                                  <div className="kind-opt">
                                    Qty {paymentData.countAdults}
                                  </div>
                                </Col>
                                {/* <Col xs={6} className="mb-4">
                                <div className="sum">
                                  ${paymentData.amountForAdults}
                                </div>
                                <div className="each-sum">$25.00 each</div>
                              </Col> */}
                                <Col xs={6} className="mb-4">
                                  <div className="kind">
                                    Kid <span>(2 - 8)</span>
                                  </div>
                                  <div className="kind-opt">
                                    Qty {paymentData.countChilds}
                                  </div>
                                </Col>
                                {/* <Col xs={6} className="mb-4">
                                <div className="sum">
                                  ${paymentData.amountForChilds}
                                </div>
                                <div className="each-sum"></div>
                              </Col> */}
                              </Row>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="info-content-footer info-content__block_bottom">
                        <div className="info-payment-total">
                          <div className="info-payment-total__title">
                            Total Amount:
                          </div>
                          {totalAmountFieledRender()}

                          {/* {paymentData.paymentStatus === "Partialy Paid" ? (
                            <div className="info-payment-total__sum">{`${currency}${paymentData.prepaidAmount}`}</div>
                          ) : (
                            <div className="info-payment-total__sum">{`${currency}${paymentData.amount}`}</div>
                          )} */}

                          {/* <div className="info-payment-total__sum">{`${currency}${paymentData.amount}`}</div> */}
                        </div>
                        <Button
                          className="form-btn go-to-btn"
                          onClick={() => setGoToPayment(true)}
                        >
                          Go to payment{"  "}
                          <img src={Arrow} alt="" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
            <Col md={6} className="p-0 d-none d-md-block">
              <div className="payment">
                <CheckoutForm
                  amount={renderAmount()}
                  contactId={contactId}
                  events={events}
                  paymentReason={paymentReason}
                />
              </div>
            </Col>
            {goToPaymant ? (
              <Col md={6} className="p-0 d-md-none d-lg-none d-xl-none">
                <div className="payment">
                  <CheckoutForm
                    paymentReason={paymentReason}
                    amount={renderAmount()}
                    contactId={contactId}
                    events={events}
                    handleGoBack={() => setGoToPayment(false)}
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Main;
